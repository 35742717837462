body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.leaflet-bar {
  background-color: black !important;
}

/* Scrollbars */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3); 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(102, 102, 102, 0.8); 
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(48, 48, 48, 0.4); 
}